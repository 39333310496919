export const fields = [
  { key: "users", label: "Konselor", _style: "min-width:100px" },
  { key: "start_time", label: "Start Time", _style: "min-width:100px" },
  { key: "end_time", label: "End Time", _style: "min-width:100px" },
  { key: "senin", label: "Senin", _style: "min-width:50px" },
  { key: "selasa", label: "Selasa", _style: "min-width:50px" },
  { key: "rabu", label: "Rabu", _style: "min-width:50px" },
  { key: "kamis", label: "Kamis", _style: "min-width:50px" },
  { key: "jumat", label: "Jumat", _style: "min-width:50px" },
  { key: "sabtu", label: "Sabtu", _style: "min-width:50px" },
  { key: "minggu", label: "Minggu", _style: "min-width:50px" },
  { key: "created_at", label: "Tanggal Dibuat", _style: "min-width:100px" },
  { key: "updated_at", label: "Tanggal Diupdate", _style: "min-width:100px" },
  { key: "action", label: "Aksi", _style: "min-width:75px" },
]
