<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addSchedule()">
                  Tambah Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
        >
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton @click="hapus(item)" color="danger" square size="sm">
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="50"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit Schedule' : 'Tambah Schedule'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col">
          <label for="Role">Konselor</label>
          <v-select
            class="mb-3"
            v-model="form.konselor_id"
            placeholder="Pilih Konselor"
            :options="computedUser"
          ></v-select>
          <CInput
            v-model="form.start_time"
            label="Start Time"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.end_time"
            label="End Time"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.senin"
            label="Senin"
            type="senin"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.selasa"
            label="Selasa"
            placeholder="ketik disini"
          />
          <CInput v-model="form.rabu" label="Rabu" placeholder="ketik disini" />
          <CInput
            v-model="form.kamis"
            label="Kamis"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.jumat"
            label="Jumat"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.sabtu"
            label="Sabtu"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.minggu"
            label="Minggu"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Schedule
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Schedule
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/schedule"
import firebase from "firebase/compat/app"
import "firebase/auth"

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      roles: [],
      users: [],
      schedule: JSON.parse(localStorage.getItem("schedule")),
      page: 1,
      total: 0,
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
    }
  },
  methods: {
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()
      this.form.role_id = this.form.role ? this.form.role.value : null
      this.form.verificator = this.form.verificators
        ? this.form.verificators.value
        : null
      this.form.konselor_id = this.form.konselor_id
        ? this.form.konselor_id.value
        : null

      // insert ke database
      this.$store
        .dispatch("schedule/addSchedule", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan schedule")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      this.form.role_id = this.form.role ? this.form.role.value : null
      this.form.verificator = this.form.verificators
        ? this.form.verificators.value
        : null
      this.form.konselor_id = this.form.konselor_id
        ? this.form.konselor_id.value
        : null

      this.$store
        .dispatch("schedule/updateSchedule", {
          id: this.form.id,
          data: this.form,
        })
        .then(() => {
          this.$toast.success("Berhasil merubah data schedule")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("schedule/deleteSchedule", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data schedule")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("schedule/getSchedule", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.item)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()

          this.getDataKonselor()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataKonselor() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "konselor",
      }

      this.$store
        .dispatch("user/getUser", params)
        .then((resp) => {
          this.users = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addSchedule() {
      this.isUpdate = false
      this.form = {}
      this.createModal = true
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    deleteAccountByScheduleId(scheduleId) {
      firebase
        .auth()
        .getSchedule(scheduleId)
        .then((scheduleRecord) => {
          // Hapus akun pengguna dengan metode delete()
          return firebase.auth().deleteSchedule(scheduleRecord.uid)
        })
        .then(() => {
          // Akun berhasil dihapus
          console.log(
            "Akun berhasil dihapus berdasarkan ID pengguna:",
            scheduleId
          )
        })
        .catch((error) => {
          // Tangani kesalahan jika ada
          console.error("Error saat menghapus akun:", error.message)
        })
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          ...item,

          users: item.users ? item.users.full_name : "Tidak ada",
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedUser() {
      if (this.form.konselors) {
        let user = {
          value: this.form.konselors,
          label: this.users.find((element) => {
            return element.id == this.form.konselors
          }).username,
        }
        this.form.konselor_id = user
      }
      return this.users.map((item) => {
        return {
          value: item.id,
          label: item.username,
        }
      })
    },
    computedVerificator() {
      console.log(this.form.verificator)
      if (this.form.verificator || this.form.verificator === 0) {
        let doc = this.verificators.find((element) => {
          return element.value == this.form.verificator
        })
        console.log(doc)
        if (doc) {
          let verificator = {
            value: doc.value,
            label: doc.label,
          }
          this.form.verificators = verificator
        }
      }
      return this.verificators.map((item) => {
        return {
          value: item.value,
          label: item.label,
        }
      })
    },
  },
  mounted() {
    console.log(firebase, "firebase")
    this.getData()
  },
}
</script>
